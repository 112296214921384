import React from 'react';
import './Programs.css';
import { programsData } from '../data/programsData';
import RightArrow from '../assets/rightArrow.png';

const Programs = () => {
  return (
    <div className='programs' id='programs'>
      {/* header */}
      <div
        className='programs-header'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1500'
      >
        <span className='stroke-text'>Explore our</span>
        <span>Programs</span>
        <span className='stroke-text'>to shape</span>
      </div>
      <div
        className='program-categories'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1500'
      >
        {programsData.map((prog) => {
          return (
            <div className='category'>
              {prog.image}
              <span>{prog.heading}</span>
              <span>{prog.details}</span>
              <div className='join-now'>
                <span>Join Now</span>
                <img src={RightArrow} alt='' />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Programs;
