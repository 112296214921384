import React, { useState } from 'react';
import './Testionial.css';
import leftArrow from '../assets/leftArrow.png';
import rightArrow from '../assets/rightArrow.png';
import { testimonialsData } from '../data/testimonialsData';

const Testionial = () => {
  const [selected, setSelected] = useState(0);
  const tLength = testimonialsData.length;
  return (
    <div className='testimonial' id='testimonial'>
      <div
        className='left-t'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1500'
      >
        <span>Testimonials</span>
        <span className='stroke-text'>What they</span>
        <span>say about us</span>
        <span className='review'>{testimonialsData[selected].review}</span>
        <span>
          <span style={{ color: 'var(--orange)' }}>
            {testimonialsData[selected].name}
          </span>{' '}
          - {testimonialsData[selected].status}
        </span>
      </div>
      {/* the right hand side now */}
      <div className='right-t'>
        {/* to create the two border at the back */}
        <div></div>
        <div></div>
        <img
          data-aos='flip-down'
          data-aos-easing='linear'
          data-aos-duration='1000'
          src={testimonialsData[selected].image}
          alt=''
          className='img1'
          style={{ opacity: 3 }}
        />
        <div className='arrows'>
          <img
            className='right-display'
            src={leftArrow}
            onClick={() => {
              selected === 0
                ? setSelected(tLength - 1)
                : setSelected((prev) => prev - 1);
            }}
          />

          <img
            src={rightArrow}
            onClick={() => {
              selected === tLength - 1
                ? setSelected(0)
                : setSelected((prev) => prev + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Testionial;
