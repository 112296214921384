import React, { useRef } from 'react';
import './Join.css';
//import emailjs from '@emailjs/browser';
import send from 'emailjs-com';

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    send
      .sendForm(
        'service_zxc8ih1',
        'template_b4j5pql',
        form.current,
        'swAsg2JNoQe85Vkt2'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className='join' id='join-us'>
      <div className='left-j'>
        <hr />
        <div>
          <span className='stroke-text'>READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className='stroke-text'> WITH US ?</span>
        </div>
      </div>
      <div className='right-j'>
        <form
          ref={form}
          className='email-container'
          onSubmit={sendEmail}
          data-aos='zoom-out'
          data-aos-easing='linear'
          data-aos-duration='1500'
        >
          <input
            type='type'
            name='user_name'
            placeholder='Enter your Full name'
          />
          <input
            type='email'
            name='user_email'
            placeholder='Enter your Email address'
          />
          {/* <input
            type='tel'
            name='user_phone'
            placeholder='Enter your Phone number'
          /> */}
          <button className='btn btn-j'>Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
