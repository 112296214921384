import React from 'react';
import { plansData } from '../data/plansData';
import { FaUserAlt } from 'react-icons/fa';
import whiteTick from '../assets/whiteTick.png';

import './Plans.css';
const Plans = () => {
  return (
    <div className='plans-container' id='plan'>
      <div className='blur plans-blur1'></div>
      <div className='blur plans-blur2'></div>
      <div
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1500'
        className='programs-header'
        style={{
          gap: '2rem',
        }}
      >
        <span className='stroke-text'>READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className='stroke-text'>NOW WITHUS</span>
      </div>
      {/* plans card */}
      <div
        className='plans'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1500'
      >
        {plansData.map((plan, i) => {
          return (
            <div className='plan'>
              {plan.icon}
              <span>{plan.name}</span>
              <span>${plan.price}</span>

              <div className='features'>
                {plan.features.map((feature, i) => {
                  return (
                    <div className='feature'>
                      <img src={whiteTick} alt='' />
                      <span key={1}>{feature}</span>
                    </div>
                  );
                })}
              </div>
              <div>
                <span>See more benefits </span>
              </div>
              <button className='btn'>
                Join now <FaUserAlt className='arrow' />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Plans;
