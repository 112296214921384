import React from 'react';
import './Header.css';
import Logo from '../assets/logo.png';
import Bars from '../assets/bars.png';
import { Link } from 'react-scroll';
// const Header = () => {
//   return (
//     <div className='header'>
//       <img src={Logo} alt='' className='logo' />
//       <ul className='header-menu'>
//         <li>Home</li>
//         <li>Programs</li>
//         <li>Why us</li>
//         <li>Plans</li>
//         <li>Testimonials</li>
//       </ul>
//     </div>
//   );
// };

//for menu bar condition
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <div className='header'>
      <img src={Logo} alt='' className='logo' />

      {menuOpen === false && mobile === true ? (
        <div
          style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px',
          }}
          onClick={() => setMenuOpen(true)}
        >
          <img
            src={Bars}
            alt=''
            style={{ width: '1.5rem', height: '1.5rem' }}
          />
        </div>
      ) : (
        <ul className='header-menu'>
          <li onClick={() => setMenuOpen(false)}>
            {' '}
            <Link
              onClick={() => setMenuOpen(false)}
              to='home'
              span={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <Link
              onClick={() => setMenuOpen(false)}
              to='programs'
              span={true}
              smooth={true}
            >
              Programs
            </Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            {' '}
            <Link
              onClick={() => setMenuOpen(false)}
              to='reasons'
              span={true}
              smooth={true}
            >
              Why us
            </Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            {' '}
            <Link
              onClick={() => setMenuOpen(false)}
              to='plan'
              span={true}
              smooth={true}
            >
              Plans
            </Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <Link
              onClick={() => setMenuOpen(false)}
              to='testimonial'
              span={true}
              smooth={true}
            ></Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
