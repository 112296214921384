import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero_image from '../assets/hero_image.png';
import hero_back from '../assets/hero_image_back.png';
import heart from '../assets/heart.png';
import calories from '../assets/calories.png';
import NumberCounter from 'number-counter';
//import { motion } from 'framer-motion';

// const Hero = () => {

//   return (
//     <div className='hero'>
//       <div className='blur hero-blur'></div>
//       <div className='left'>
//         <Header />

//         {/* the best head ad */}
//         <div className='the-ad'>

//           <span>the best fitness club in the town</span>
//         </div>
//         {/* hero heading */}
//         <div className='hero-text'>
//           <div>
//             <span className='stroke-text'>Shape</span>
//             <span>Your</span>
//           </div>
//           <div>
//             <div>
//               <span>Ideal body</span>
//             </div>
//             <span className='text'>
//               In here we will help you to shape and build your ideal body and
//               live up your life to fullest
//             </span>
//           </div>
//         </div>
//         {/* figures */}
//         <div className='figures'>
//           <div>
//             <span>+140</span>
//             <span>expert coachs</span>
//           </div>
//           <div>
//             <span>+978</span>
//             <span>members joined</span>
//           </div>
//           <div>
//             <span>+50</span>
//             <span>fitness programs</span>
//           </div>
//         </div>
//         {/* here button */}
//         <div className='hero-button'>
//           <div className='btn'>Get Started</div>
//           <div className='btn'>Learn More</div>
//         </div>
//       </div>
//       <div className='right'>
//         <button className='btn'>Join Now</button>

//         <div className='heart-rate'>
//           <img src={heart} alt='' />
//           <span>Heart Rate</span>
//           <span>116 bpm</span>
//         </div>
//         {/* hero images */}
//         <img src={hero_image} alt='' className='hero-image' />
//         <img src={hero_back} alt='' className='hero-image-back' />
//         {/* colaries */}
//         <div className='calories'>
//           <img src={calories} alt='' />
//           <div>
//             <span>Colaries Burned</span>
//             <span>220 kcal</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;
//to animate now
// const Hero = () => {
//   //const transition = { type: 'spring', duration: 3 };
//   return (
//     <div className='hero' id='home'>
//       <div className='blur hero-blur'></div>
//       <div className='left'>
//         <Header />

//         {/* the best head ad */}
//         <div className='the-ad'>
//           <div className='ball'></div>

//           {/* <motion.div
//             initial={{ left: '238px' }}
//             whileInView={{ left: '8px' }}
//             transition={{ ...transition, type: 'tween' }}
//             viewport={{ once: true }}
//           ></motion.div> */}
//           <span>the best fitness club in the town</span>
//         </div>
//         {/* hero heading */}
//         <div className='hero-text'>
//           <div>
//             <span className='stroke-text'>Shape</span>
//             <span> Your</span>
//           </div>
//           <div>
//             <div>
//               <span>Ideal body</span>
//             </div>
//             <span className='text'>
//               In here we will help you to shape and build your ideal body and
//               live up your life to fullest
//             </span>
//           </div>
//         </div>
//         {/* figures */}
//         <div className='figures'>
//           <div>
//             <span>+140</span>
//             <span>expert coachs</span>
//           </div>
//           <div>
//             <span>+978</span>
//             <span>members joined</span>
//           </div>
//           <div>
//             <span>+50</span>
//             <span>fitness programs</span>
//           </div>
//         </div>
//         {/* here button */}
//         <div className='hero-button'>
//           <div className='btn'>Get Started</div>
//           <div className='btn'>Learn More</div>
//         </div>
//       </div>
//       <div className='right'>
//         <button className='btn'>Join Now</button>

//         <div className='heart-rate'>
//           <img src={heart} alt='' />
//           <span>Heart Rate</span>
//           <span>116 bpm</span>
//         </div>
//         {/* hero images */}
//         <img src={hero_image} alt='' className='hero-image' />
//         <img src={hero_back} alt='' className='hero-image-back' />
//         {/* colaries */}
//         <div className='calories'>
//           <img src={calories} alt='' />
//           <div>
//             <span>Colaries Burned</span>
//             <span>220 kcal</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

//to make the number count

const Hero = () => {
  return (
    <div className='hero' id='home'>
      <div className='blur hero-blur'></div>
      <div className='left'>
        <Header />

        {/* the best head ad */}
        <div className='the-ad'>
          <div className='ball'></div>

          <span>the best fitness club in the town</span>
        </div>
        {/* hero heading */}
        <div className='hero-text'>
          <div>
            <span className='stroke-text'>Shape</span>
            <span> Your</span>
          </div>
          <div>
            <div>
              <span>Ideal body</span>
            </div>
            <span className='text'>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* figures */}
        <div className='figures'>
          <div>
            <span>
              <NumberCounter
                end={140}
                start={50}
                delay='4'
                preFix='+'
              ></NumberCounter>
            </span>
            <span>expert coachs</span>
          </div>
          <div>
            <span>
              {' '}
              <NumberCounter
                end={980}
                start={700}
                delay='4'
                preFix='+'
              ></NumberCounter>
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
              {' '}
              <NumberCounter
                end={50}
                start={0}
                delay='4'
                preFix='+'
              ></NumberCounter>
            </span>
            <span>fitness programs</span>
          </div>
        </div>
        {/* here button */}
        <div className='hero-button'>
          <div className='btn'>Get Started</div>
          <div className='btn'>Learn More</div>
        </div>
      </div>
      <div className='right'>
        <button className='btn'>Join Now</button>

        <div
          className='heart-rate'
          data-aos='fade-left'
          data-aos-easing='linear'
          data-aos-duration='1500'
        >
          <img src={heart} alt='' />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </div>
        {/* hero images */}
        <img src={hero_image} alt='' className='hero-image' />
        <img src={hero_back} alt='' className='hero-image-back' />
        {/* colaries */}
        <div className='calories'>
          <img src={calories} alt='' />
          <div>
            <span>Colaries Burned</span>
            <span>220 kcal</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
