import React from 'react';
import './App.css';
import Hero from '../src/components/Hero';
import Pro from '../src/components/Programs';
import Rea from '../src/components/Reasons';
import Plans from '../src/components/Plans';
import Testify from '../src/components/Testionial';
import Join from '../src/components/Join';
import Footer from '../src/components/Footer';
function App() {
  return (
    <div className='App'>
      <Hero />
      <Pro />
      <Rea />
      <Plans />
      <Testify />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
